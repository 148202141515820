import { Button } from "@nextui-org/react";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import {
  CallControls,
  PaginatedGridLayout,
  CallingState,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { useCallback, useEffect } from "react";
import { getMeetingInfo } from "../utils/meetingUtils";

// const apiKey = "s8a8kb6zt7t6";
// const user = { id: "test-guest", name: "Guest", type: "guest" };

// const client = new StreamVideoClient({ apiKey, user });

const MeetingUi = ({ call }) => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  useEffect(() => {
    getMeetingInfo(call).then((resp) => {
      console.log(`call info: `, resp);
    });
    // try {
    //   call?.join();
    // } catch (error) {
    //   console.error(error);
    // }
  }, [call]);

  const callingStateUI = useCallback(() => {
    switch (callingState) {
      case CallingState.UNKNOWN:
      case CallingState.IDLE:
        return (
          <div className='w-full h-full flex justify-center items-center'>
            <Button type='primary' onClick={() => call?.join()}>
              Join
            </Button>
          </div>
        );

      case CallingState.RINGING:
        //   return <IncomingCallScreen />;
        return "Ringing";

      case CallingState.JOINING:
        //   return <LoadingCallScreen />;
        return "Joining";

      case CallingState.JOINED:
        return (
          <>
            <PaginatedGridLayout />
            <CallControls />
          </>
        );

      case CallingState.LEFT:
        return (
          <Button type='primary' onClick={() => call?.join()}>
            Join
          </Button>
        );

      case CallingState.RECONNECTING:
      case CallingState.MIGRATING:
        return "Restoring";

      case CallingState.RECONNECTING_FAILED:
        return (
          <Button type='primary' onClick={() => call?.join()}>
            Rejoin
          </Button>
        );

      case CallingState.OFFLINE:
        return "Off line";

      default:
        const exhaustiveCheck = callingState;
        throw new Error(`Unknown calling state: ${exhaustiveCheck}`);
    }
  }, [callingState, call]);

  return callingStateUI();

  //   return (
  //     <div className='bg-gray-200 w-full h-lvh'>
  //       {/* {callingStateUI()} */}
  //       {/* <StreamVideo client={client}>
  //         <StreamTheme>
  //           {call != null ? (
  //             callingState !== CallingState.LEFT ? (
  //               <StreamCall call={call}>
  //                 <PaginatedGridLayout />
  //                 <CallControls />
  //               </StreamCall>
  //             ) : null
  //           ) : null}
  //         </StreamTheme>
  //       </StreamVideo> */}
  //       {callingStateUI()}
  //     </div>
  //   );
};

export default MeetingUi;
