import { useCallback, useEffect, useState } from "react";
import "./App.css";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import MeetingUi from "./views/MeetingUi";
// import PinField from "react-pin-field";
import {
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
} from "@stream-io/video-react-sdk";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  NextUIProvider,
} from "@nextui-org/react";
import { getMeetingInfo } from "./utils/meetingUtils";

const apiKey = "uhngfh7w73g7";
const user = { id: "test-guest", name: "Guest", type: "guest" };

const client = new StreamVideoClient({ apiKey, user });

function App() {
  const [call, setCall] = useState();
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  // const pinInputRef = useRef();

  const verifyCallId = useCallback(async () => {
    try {
      const meetingId = window.empaMeetingParams?.callId;
      const tempCall = client.call("default", meetingId);
      const meetingInfo = await getMeetingInfo(tempCall);
      console.log("meetingInfo ", meetingInfo);
      setCall(tempCall);
    } catch (error) {
      console.error(error);
      // alert("wrong meeting");
      setIsPinModalOpen(true);
    }
  }, []);

  useEffect(() => {
    verifyCallId();
  }, [verifyCallId]);

  return (
    <NextUIProvider className='h-full'>
      {call ? (
        <StreamVideo client={client} className='h-full w-full'>
          <StreamTheme className='h-full w-full'>
            <StreamCall call={call}>
              <MeetingUi call={call} />
            </StreamCall>
          </StreamTheme>
        </StreamVideo>
      ) : null}
      <Modal isOpen={isPinModalOpen}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Meeting Not Found
              </ModalHeader>
              <ModalBody>
                {/* <PinField
                  length={4}
                  ref={pinInputRef}
                  onComplete={onPinComplete}
                  validate={/^[0-9]$/}
                /> */}
                {/* <Input
                  type='number'
                  onValueChange={onPinNumberChange}
                  placeholder='verify code'
                /> */}
                <span>Meeting not found, please check your meeting url</span>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </NextUIProvider>
  );
}

export default App;
